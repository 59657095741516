import { Component, OnInit, Injectable } from '@angular/core';
import { SyndicationService } from 'src/app/services/syndication/syndication.service';
import { JsonPipe } from '@angular/common';
import { Product } from 'src/app/shared/response-models/product';
import { ActivatedRoute, Router } from '@angular/router';
import { ProductRangeRespose } from 'src/app/shared/response-models/product-range-response';
import { jsonpCallbackContext } from '@angular/common/http/src/module';
import { GoogleAnalyticsService } from 'src/app/services/stats/google-analytics.service';
import { Params } from '@angular/router';
import { StyleOptions } from 'src/app/shared/style-options';
import { StyleOptionsService } from 'src/app/services/style-options/style-options.service';
import { debounce } from 'ts-debounce';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
@Component({
  selector: 'app-manufacturer-syndication',
  templateUrl: './manufacturer-syndication.component.html',
  styleUrls: ['./manufacturer-syndication.component.scss']
})

@Injectable({
  providedIn: 'root'
})

export class ManufacturerSyndicationComponent implements OnInit {

  // Populated from response.
  public manufacturerId: number;
  public products: Product[] = [];
  public productRanges: string[] = [];

  public dataLoaded = false;
  public dataError = false;
  public errorReason = '';
  public rangeFilter: string = null;

  // Populated from url
  public accessId: string;
  public rangeName: string;
  public styleOptions: StyleOptions;

  currentNumberOfLoadedProducts = 0;

  constructor(private syndicationService: SyndicationService,
    private route: ActivatedRoute,
    private statLogger: GoogleAnalyticsService,
    private styleService: StyleOptionsService) { }

  ngOnInit() {
    this.styleOptions = this.styleService.getStyleOptions();
    this.accessId = this.route.snapshot.params['accessId'];

    this.route.queryParams.subscribe(params => {
      this.rangeName = this.GetValueOrDefault(params, 'rangeName');

      if (!this.accessId) {
        this.SetPageError('No widget ID supplied');
        return;
      }

      if (this.rangeName) {
        this.rangeFilter = this.rangeName;
        this.CreateForProductRange(this.accessId, this.rangeName);
      } else {
        this.CreateForAllProducts(this.accessId);
      }
    });

    this.populateProductRanges(this.accessId);
  }

  // Range drop down

  populateProductRanges(accessId: string): any {
    this.syndicationService.getRanges(accessId)
      .subscribe(result => {
        if (result) {
          this.productRanges = result.productRanges.map(function(e) { return e.name; });
        }
      }, error => {
    }, () => {
    });
  }


  // Search by range

  searchByRange(filter: string) {
    if (filter != null) {
      this.CreateForProductRange(this.accessId, filter);
    }
  }


  CreateForProductRange(accessId: string, rangeName: string): any {
    this.syndicationService.getManufacturerProductsByRange({
      accessId: accessId,
      rangeName: rangeName,
    }).subscribe(result => {
      this.dataLoaded = true;
      this.products = result.products;
      this.manufacturerId = result.rpsNumber;
    }, error => {
      this.handleError(error);
    }, () => {
      this.handleOnComplete();
    });
  }

  // Search all

  CreateForAllProducts(accessId: string): any {
    this.syndicationService.getManufacturerProducts({
      accessId: accessId,
      take: 100,
      skip: this.currentNumberOfLoadedProducts
    }).subscribe(result => {
      this.dataLoaded = true;
      if (result) {
        this.products = this.products.concat(result.products);
        this.manufacturerId = result.rpsNumber;
      }
    }, error => {
      this.handleError(error);
    }, () => {
      this.handleOnComplete();
      this.currentNumberOfLoadedProducts = this.products.length;
    });
  }

  loadMoreProducts(event) {
    if (this.rangeFilter == null) {
      this.CreateForAllProducts(this.accessId);
    }
  }



  // Form helpers

  GetValueOrDefault(params, paramName: string) {
    return params[paramName] || params[paramName.toLowerCase()] || this.styleOptions[paramName];
  }

  handleError(error) {
    this.dataLoaded = true;

    if (error.status === 400) {
      this.SetPageError('Bad request');
    } else {
      this.SetPageError('Failed to load products');
    }
  }

  handleOnComplete() {
    this.dataLoaded = true;

    if (this.products.length === 0) {
      this.SetPageError('No products to show');
    }
  }

  SetPageError(reason: string) {
    this.dataError = true;
    this.errorReason = reason;
  }

  childProductShown(eventData: string) {
    const product: Product = JSON.parse(eventData);

    this.statLogger.LogImpression({
      'name': product.name,
      'brand': this.manufacturerId,
      'list': 'Syndication',
      'category': '',
      'dimension1': 'Product',
      'dimension3': 'Syndication'
    });
  }

  clearFilter() {
    this.rangeFilter = null;
    this.products = [];
    this.currentNumberOfLoadedProducts = 0;
    this.CreateForAllProducts(this.accessId);
  }
}

import { Injectable } from '@angular/core';
import { StyleOptions } from 'src/app/shared/style-options';
import { Params, ActivatedRoute } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StyleOptionsService {
  private styleOptions: StyleOptions = new StyleOptions();

  constructor(private activatedRoute: ActivatedRoute) {
    this.activatedRoute.queryParams.subscribe(params => {
      this.updateSetupFromUrl(params);
    });
  }

  getStyleOptions(): StyleOptions {
    return this.styleOptions;
  }

  private updateSetupFromUrl(params: Params): any {
    this.styleOptions.font = this.GetValueOrDefault(params, 'font');
    this.styleOptions.fontSize = this.GetValueOrDefault(params, 'fontSize');
    this.styleOptions.fontColour = this.GetValueOrDefault(params, 'fontColour');
    this.styleOptions.borderColour = this.GetValueOrDefault(params, 'borderColour');
    this.styleOptions.backgroundColour = this.GetValueOrDefault(params, 'backgroundColour');
    this.styleOptions.linkColour = this.GetValueOrDefault(params, 'linkColour');
    this.styleOptions.buttonColour = this.GetValueOrDefault(params, 'buttonColour');
    this.styleOptions.buttonTextColour = this.GetValueOrDefault(params, 'buttonTextColour');
    this.styleOptions.height = this.GetValueOrDefault(params, 'height');
    this.styleOptions.displayImage = (this.GetValueOrDefault(params, 'displayImage') === 'true'
    || this.GetValueOrDefault(params, 'displayImage') === true);
    this.styleOptions.displayText = (this.GetValueOrDefault(params, 'displayText') === 'true'
     || this.GetValueOrDefault(params, 'displayText') === true);
   }

  private GetValueOrDefault(params, paramName: string) {
    return params[paramName] || params[paramName.toLowerCase()] || this.styleOptions[paramName];
  }

  getBodyStyles() {
    return {
      'font-family': this.styleOptions.font,
      'font-size': this.styleOptions.fontSize + 'px',
      'color': '#' + this.styleOptions.fontColour,
      'background-color': '#' + this.styleOptions.backgroundColour,
      'border-color': '#' + this.styleOptions.borderColour
    };
  }
  getLinkStyles() {
    return {
      'font-size': parseInt(this.styleOptions.fontSize.toString(), 10) + 2 + 'px',
      'color': '#' + this.styleOptions.linkColour
    };
  }

  getButtonStyles() {
    return {
      'background-color': '#' + this.styleOptions.buttonColour,
      'color': '#' + this.styleOptions.buttonTextColour
    };
  }
  getFontStyle() {
    return { 'font-family': this.styleOptions.font};
  }
  getFontSize() {
    return { 'font-size': this.styleOptions.fontSize + 'px'};
  }
  getFontColour() {
    return { 'color': '#' + this.styleOptions.fontColour};
  }
  getBackgroundColour() {
    return { 'background-color': '#' + this.styleOptions.backgroundColour};
  }
  getLinkColour() {
    return { 'color': '#' + this.styleOptions.linkColour};
  }
  getbuttonTextColour() {
    return { 'color': '#' + this.styleOptions.buttonTextColour};
  }
  getButtonColour() {
    return { 'background-color': '#' + this.styleOptions.buttonColour};
  }
  getBorderColour() {
    return { 'border-color': '#' + this.styleOptions.borderColour};
  }
}

import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConfigService } from '../config/config.service';
import { ProductRangeRespose } from 'src/app/shared/response-models/product-range-response';
import { AllProductsResponse } from 'src/app/shared/response-models/all-products-response';
import { ProductDetail } from 'src/app/shared/response-models/product';
import { WidgetData } from '../../shared/post-models/widget-data';
import { WidgetProductData } from 'src/app/shared/post-models/widget-product-data';
import { RangeInfoResponse } from 'src/app/shared/response-models/range-info-response';

@Injectable({
  providedIn: 'root'
})
export class SyndicationService {

  constructor(private http: HttpClient, private config: ConfigService)  {  }

  getManufacturerProducts(requestData: WidgetData): Observable<AllProductsResponse> {
    const baseUrl = this.config.settings.syndicationApi.baseUrl;
    const queryPath = this.config.settings.syndicationApi.productsUrl;

    return this.http.post<AllProductsResponse>(baseUrl + queryPath, requestData);
  }

  getManufacturerProductsByRange(requestData: WidgetData): Observable<ProductRangeRespose> {
    const baseUrl = this.config.settings.syndicationApi.baseUrl;
    const queryPath = this.config.settings.syndicationApi.productsByRangeUrl;

    return this.http.post<ProductRangeRespose>(baseUrl + queryPath, requestData);
  }

  getProductDetail(requestData: WidgetProductData): Observable<ProductDetail> {
    const baseUrl = this.config.settings.syndicationApi.baseUrl;
    const queryPath = this.config.settings.syndicationApi.productDetailUrl;

    return this.http.post<ProductDetail>(baseUrl + queryPath, requestData).pipe(map(result => {
      return new ProductDetail(result);
    }));
  }

  getRanges(accessId: string): Observable<RangeInfoResponse> {
    const baseUrl = this.config.settings.syndicationApi.baseUrl;
    const queryPath = this.config.settings.syndicationApi.productRanges;

    return this.http.get<RangeInfoResponse>(baseUrl + queryPath + '?accessId=' + accessId);
  }
}

import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Product } from 'src/app/shared/response-models/product';
import { StyleOptions } from 'src/app/shared/style-options';
import { StyleOptionsService } from 'src/app/services/style-options/style-options.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {
  @Input() product: Product;

  @Input() accessId: string;
  @Input() styleOptions: StyleOptions;


  @Output()
  productShown = new EventEmitter<string>();


  constructor(public styleService: StyleOptionsService) { }

  ngOnInit() {
  }

  statTrigger() {
    this.productShown.emit(JSON.stringify(this.product));
  }
}

import { Component, OnInit } from '@angular/core';
import { SyndicationService } from 'src/app/services/syndication/syndication.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { Product, Manufacturer, Address } from 'src/app/shared/response-models/product';
import { GoogleAnalyticsService } from 'src/app/services/stats/google-analytics.service';
import { Clipboard } from 'ts-clipboard';
import { StyleOptionsService } from '../../services/style-options/style-options.service';

@Component({
  selector: 'app-product-detail',
  templateUrl: './product-detail.component.html',
  styleUrls: ['./product-detail.component.scss']
})

export class ProductDetailComponent implements OnInit {

  product: Product;
  manufacturer: Manufacturer;

  dataLoaded = false;
  dataError = false;
  errorReason = '';
  userToComplete = '[______]';
  accessId: string;

  isRoot = false;

  public copiedText = 'Copy spec to clipboard';

  constructor(
    private syndicationService: SyndicationService,
    private route: ActivatedRoute,
    private router: Router,
    private googleAnalyticsService: GoogleAnalyticsService,
    private location: Location,
    private styleService: StyleOptionsService
  ) {
    this.product = new Product();
    this.manufacturer = new Manufacturer();
    this.manufacturer.address = new Address();

    this.route.queryParams.subscribe(params => {
      this.isRoot = params['root'] === '1';
    });
  }

  getFormattedUrl(): string {
    if (this.manufacturer.address.url === undefined) {
      return '';
    }

    if (this.manufacturer.address.url.startsWith('http')) {
      return this.manufacturer.address.url;
    }

    return 'http://' + this.manufacturer.address.url;
  }

  ngOnInit() {
    this.accessId = this.route.snapshot.params['accessId'];

    this.syndicationService.getProductDetail({
      accessId: this.accessId,
      referrer: this.router.url,
      productId: this.route.snapshot.params['productId']
    }).subscribe(result => {
      // Have intermittently seen a product null error during development.
      // Not sure what caused it, but this null check should stop it breaking anything.
      this.product = result.product != null ? result.product : new Product();
      this.manufacturer = result.manufacturer != null ? result.manufacturer : new Manufacturer();
      this.googleAnalyticsService.LogDetail({
        name: this.product.name,
        brand: this.manufacturer.rpsNumber,
        category: '',
        dimension1: 'Product',
        dimension3: 'Syndication',
      });
    }, error => {
      this.handleError(error);
    }, () => {
      this.handleOnComplete();
    });
  }

  handleError(error) {
    this.dataLoaded = true;

    if (error.status === 400) {
      this.SetPageError('Bad request');
    } else {
      this.SetPageError(error);
    }
  }

  handleOnComplete() {
    this.dataLoaded = true;
  }

  SetPageError(reason: string) {
    this.dataError = true;
    this.errorReason = reason;
  }

  async delay(ms: number) {
    await new Promise(resolve => setTimeout(() => resolve(), ms)).then();
  }

  copySpecClick(): void {
    // Catches cases of this being run before product has loaded, or run on a product that failed to load
    if (this.product.properties === undefined) {
      return;
    }
    if (this.copiedText === 'Copy spec to clipboard') {
      this.copiedText = '<i class="fa fa-check"></i>' + ' Copied';
      this.delay(2000).then(any => {
      this.copiedText = 'Copy spec to clipboard';
      });
    }

    this.googleAnalyticsService.LogCopyToClipboard({
      name: this.product.name,
      brand: this.manufacturer.rpsNumber,
      category: '',
      dimension1: 'Product',
      dimension3: 'Syndication',
      price: 1,
      variant: '',
      quantity: 1
    });

    let specText = this.product.name;

    if (this.manufacturer !== undefined) {
      specText += `${this.manufacturer.stringify()}`;
    }

    // In v1 of NBS Plus Syndication, Product Reference came under Manufacturer. It comes through from the Product Properties rather
    // than the Manufacturer, so it sits a level higher. As far as we know only Building needs this, and it works correctly.
    // If anything isn't showing the product reference properly, consider working out a way to move it under the manufacturer.

    if (this.product.properties.length > 0) {
      specText += this.product.stringifyProperties();
    }

    Clipboard.copy(specText);
  }
}

import { Injectable } from '@angular/core';
import { IAppConfig } from '../../interfaces/app-config';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})

export class ConfigService {
  public settings: IAppConfig;

  constructor(private http: HttpClient) { }

  load() {
    return new Promise<void>((resolve, reject) => {
      this.http.get(environment.configFile).toPromise().then((response: IAppConfig) => {
        this.settings = <IAppConfig>response;
        resolve();
      }).catch((response: any) => {
        reject(`Could not load file '${environment.configFile}': ${JSON.stringify(response)}`);
      });
    });
  }
}

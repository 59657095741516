export class ProductProperty {
    id: string;
    parentId: string;
    name: string;
    unit: string;
    propertyValues: ProductPropertyValue[];

    constructor(productProperty?: ProductProperty) {
        if (productProperty !== undefined) {
            this.id = productProperty.id;
            this.parentId = productProperty.parentId;
            this.name = productProperty.name;
            this.unit = productProperty.unit;
            this.propertyValues = productProperty.propertyValues;
        }
    }

    stringifyValues(): string {
        let returnString = '';

        // Units (mm, m, v) don't have nulls in the database, they're in as '[no unit]'.
        // If a unit exists, it needs appending to the value.
        const unitText = this.unit !== '[no unit]' ? ` ${this.unit}` : '';

        let iterationCount = 0;
        for (const value of this.propertyValues) {
            iterationCount++;
            if (iterationCount > 1) {
                returnString += `/ ${value.value}${unitText}`;
            } else {
                returnString += `${value.value}${unitText}`;
            }
        }
        return returnString;
    }
}

export class Product {
    name: string;
    imageUrl: string;
    description: string;
    productId: string;

    productRef: string;
    visible: boolean;
    defaultGuidance: string;
    generalGuidance: string;
    optionGuidance: string;
    lastModified: Date;
    properties: ProductProperty[];

    constructor(product?: Product) {
        if (product !== undefined) {
            this.name = product.name;
            this.imageUrl = product.imageUrl;
            this.description = product.description;
            this.productId = product.productId;
            this.productRef = product.productRef;
            this.visible = product.visible;
            this.defaultGuidance = product.defaultGuidance;
            this.generalGuidance = product.generalGuidance;
            this.optionGuidance = product.optionGuidance;
            this.lastModified = product.lastModified;
            if (product.properties != null) {
                product.properties.forEach(function(property: ProductProperty) {
                    if (this.properties === undefined || this.properties.length === 0) {
                        this.properties = [ new ProductProperty(property) ];
                    } else {
                        this.properties.push(new ProductProperty(property));
                    }
                }, this);
            }
        }
    }

    public stringifyProperties(): string {
        let returnString = '';
        const levelOneProperties = this.properties.filter(x => x.parentId == null);

        for (const property of levelOneProperties) {
            // \n = new line, \t = tab, \u2022 = bullet point
            returnString += `\n\t\u2022 ${property.name}: ${property.stringifyValues()}`;
            for (const levelTwoProperty of this.properties.filter(x => x.parentId === property.id)) {
                returnString += `\n\t\t- ${levelTwoProperty.name}: ${levelTwoProperty.stringifyValues()}`;
            }
        }
        return returnString;
    }
}

export class Address {
    addressLine1: string;
    addressLine2: string;
    addressLine3: string;
    addressLine4: string;
    addressLine5: string;
    postcode: string;
    email: string;
    url: string;
    telephone: string;
    fax: string;

    constructor(address?: Address) {
        if (address !== undefined) {
            this.addressLine1 = address.addressLine1;
            this.addressLine2 = address.addressLine2;
            this.addressLine3 = address.addressLine3;
            this.addressLine4 = address.addressLine4;
            this.addressLine5 = address.addressLine5;
            this.postcode = address.postcode;
            this.email = address.email;
            this.url = address.url;
            this.telephone = address.telephone;
            this.fax = address.fax;
        }
    }

    stringify(): string {
        let returnString = '';
        if (this.url != null) {
            returnString += `\n\t\t- Web: ${this.url}`;
        }
        if (this.email != null) {
            returnString += `\n\t\t- Email: ${this.email}`;
        }
        if (this.telephone != null) {
            returnString += `\n\t\t- Tel: ${this.telephone}`;
        }
        if (this.fax != null) {
            returnString += `\n\t\t- Fax: ${this.fax}`;
        }
        const stringyPostalAddress = this.stringifyPostalAddress();
        if (stringyPostalAddress != null) {
            returnString += stringyPostalAddress;
        }
        return returnString;
    }

    stringifyPostalAddress(): string {
        const addressComponentsWithData: string[] = [];

        if (this.addressLine1 != null && this.addressLine1 !== '') {
            addressComponentsWithData.push(this.addressLine1);
        }
        if (this.addressLine2 != null && this.addressLine2 !== '') {
            addressComponentsWithData.push(this.addressLine2);
        }
        if (this.addressLine3 != null && this.addressLine3 !== '') {
            addressComponentsWithData.push(this.addressLine3);
        }
        if (this.addressLine4 != null && this.addressLine4 !== '') {
            addressComponentsWithData.push(this.addressLine4);
        }
        if (this.addressLine5 != null && this.addressLine5 !== '') {
            addressComponentsWithData.push(this.addressLine5);
        }
        if (this.postcode != null && this.postcode !== '') {
            addressComponentsWithData.push(this.postcode);
        }

        // This is null so that if no address properties exist, the address as a whole will be null and the calling method can exclude it.
        let stringyAddress = null;
        for (let i = 0; i < addressComponentsWithData.length; i++) {
            if (i === 0) {
                stringyAddress = `\n\t\t- Address: ${addressComponentsWithData[i]}`;
            } else {
                stringyAddress += `, ${addressComponentsWithData[i]}`;
            }
        }

        return stringyAddress;
    }
}

export class Manufacturer {
    name: string;
    address: Address;
    rpsNumber: number;

    constructor(manufacturer?: Manufacturer) {
        if (manufacturer !== undefined) {
            this.name = manufacturer.name;
            this.address = new Address(manufacturer.address);
            this.rpsNumber = manufacturer.rpsNumber;
        }
    }

    stringify(): string {
        let returnString = `\n\t\u2022 Manufacturer: ${this.name}`;
        if (this.address !== undefined) {
            returnString += this.address.stringify();
        }
        return returnString;
    }
}

export class ProductDetail {
    product: Product;
    manufacturer: Manufacturer;

    // Constructor exists like this so that we can create a new typed object from an HTTP response.
    // Without this the http response is just a plain object, and we can't use any methods on the object.
    constructor(productDetail?: ProductDetail) {
        if (productDetail !== undefined) {
            this.product = new Product(productDetail.product);
            this.manufacturer = new Manufacturer(productDetail.manufacturer);
        }
    }
}

export class ProductPropertyValue {
    guidance: string;
    value: string;
}

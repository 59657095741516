import { Injectable } from '@angular/core';
import { ImpressionStat } from 'src/app/shared/stat-models/impression-stat';
import { DetailStat } from 'src/app/shared/stat-models/detail-stat';
import { CopyToClipboardStat } from 'src/app/shared/stat-models/copy-to-clipboard-stat';
import { debounce } from 'ts-debounce';

declare let dataLayer: any;

@Injectable({
  providedIn: 'root'
})
export class GoogleAnalyticsService {
  LogImpressionDebounced: any;

  constructor() {

    this.LogImpressionDebounced = debounce(() => {
      let i = 0;
      let j = 0;
      let temparray;
      const chunk = 15;

      for (i = 0, j = this.impressionStats.length; i < j; i += chunk) {
        temparray = this.impressionStats.slice(i, i + chunk);
        dataLayer.push({
          'event': 'Impression',
          'ecommerce': {
            'impressions': this.impressionStats
          }
        });
      }
      // Delete all impressions
      this.impressionStats = [];
    }, 1000);

  }

  impressionStats: any[] = [];

  LogImpression(impressionStat: ImpressionStat) {
    this.impressionStats.push(impressionStat);
    this.LogImpressionDebounced();
  }

  LogDetail(detailStat: DetailStat) {
    dataLayer.push({
      'event': 'DetailView',
      'ecommerce': {
        'detail': {
          'actionField': {},
          'products': [detailStat]
        }
      }
    });
  }

  LogCopyToClipboard(copyToClipboardStat: CopyToClipboardStat) {
    dataLayer.push({
      'event': 'Purchase',
      'ecommerce': {
        'purchase': {
          'actionField': {
            'id': this.createGuid(),
            'affiliation': 'syndication',
            'revenue': 1,
            'tax': 0,
            'shipping': 0
          },
          'products': [copyToClipboardStat]
        }
      }
    });
  }

  createGuid(): string {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }

    return s4() + s4() + s4() + s4() +
      s4() + s4() + s4() + s4();
  }
}

export class StyleOptions {
  font = 'Arial';
  backgroundColour = 'FFFFFFF';
  fontSize =  14;
  fontColour = '333333';
  linkColour = '39B1CB';
  buttonColour = '39B1CB';
  displayImage = true;
  displayText = true;
  height = '700px';
  borderColour = 'dbe0de';
  buttonTextColour = 'ffffff';
}
